/* Customize your main colors in :root variables */
:root {
  --main-background-color: #222225;
  --card-background-color: #111;
  --countdown-background-color: #af121b;
  --main-text-color:#F7F6F4;
  --title-text-color:#af121b;
}

body{
/*  background-color:var(--main-background-color);*/
  background: var(--main-background-color);
  background-size: cover;
  font-family: 'Roboto', cursive;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



